import Axios from 'axios';


function HomePage() {


  const verify = () =>{
    const url = process.env.REACT_APP_API_URL;
    const queryParameters = new URLSearchParams(window.location.search)
    console.log(queryParameters.get("token"))
    const token = queryParameters.get("token")
    Axios.get(`${url}/api/auth/verify/${token}`)
    .then((res) => {
        return res.data.message;

    }).catch((err) => {
        // return 'Error occured while verifying your email';
        return err.response.data.message;
    });
  };

    return (
      <div>
        Verify 
        { verify()}
          
      </div>
    );
 }
 
 export default HomePage;