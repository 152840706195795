import React, {useState} from 'react';


import Select from 'react-select';
import '../Styles/Navbar.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faPlay, faGear, faSignIn, faSignOut } from "@fortawesome/free-solid-svg-icons";
import Axios from 'axios';
// import {Navigate} from "react-router-dom";
import DarkMode from '../Components/DarkMode/DarkMode';
import nLanguages from '../Components/constants/nLanguages';
import { useTranslation } from "react-i18next";
import i18n from "../Components/i18n/i18n";
import languages from '../Components/constants/languages';
import themes from '../Components/constants/themes';
// import "../i18n";



const Navbar = ({ Cookies, getBarIcon, userLang, setUserLang, userLang_id, setUserLang_id, userTheme,
    setUserTheme,userName, fontSize, setFontSize, save, localcompile}) => {

      // const [userName, setUserName] = useState('');

    // const languages = [
    //     { value: "c", label: "C" },
    //     { value: "cpp", label: "C++" },
    //     { value: "python", label: "Python" },
    //     { value: "java", label: "Java" },
    // ];



    

    const { i18n, t } = useTranslation();


   


    
    function Login() {
     
      //  if(Cookies.get('Code')!=null)
      
      if(localStorage.getItem('Token')!=null)
            return <a href="/Logout"><FontAwesomeIcon icon={faSignOut} style={{color:'var(--icons-color)'}} size="2x"/></a>
        else
            return <a href="/Login"><FontAwesomeIcon icon={faSignIn} style={{color:'var(--icons-color)'}} size="2x"/></a>    
          }


            const handleClick = () => {
              alert('ddd');
            };

            const onChangeLang = (e) => {
              const lang_code = e.target.value;
              i18n.changeLanguage(lang_code);
              if(lang_code === 'ar')
              {
                document.body.style.direction = 'rtl';
              }
              else
              {
                document.body.style.direction = 'ltr';
              }
              

            };

    return (
        <div className="navbar">
            
            
            <div className='title-icon'>
              {getBarIcon()}
              <span className='title'> SysWalk</span>

              {/* InstRun */}

              
              </div>
              
              <div className='bar'>
             <button className="run-btn-play" onClick={(e) => {save();localcompile()}} >
                      <FontAwesomeIcon icon={faPlay}  /> {t("run")}
              </button>

              
              </div>
              
              {/* <button className="run-btn-stop run-btn-play"  >
                      <FontAwesomeIcon icon={faStop} /> Stop
              </button> */}

              {/* {Cookies.get('Code')} */}
              {/* {/localStorage.getItem('Token')} */}
              
                {/* {userName==='' && 
                get_username()}
                 */}
                <div className='title-icon'>
                <h2>{userName}</h2>
                </div>
                
                {/* <span onClick={handleClick} className='circle'>{Array.from(userName)[0]}</span> */}
                {(localStorage.getItem('Token')!=null) &&
                <span onClick={handleClick} className='circle'>{userName.slice(0,2)}</span>
                }
            
              

                
                <DarkMode userTheme={userTheme} setUserTheme={setUserTheme}/>
            

                <div className='title-icon'>
            <Select options={languages} value={userLang}
            style={{color:'var(--icons-color)', backgroundColor:'var(--body-background-color)'}}
                onChange={(e) => {setUserLang(e.value);setUserLang_id(e.id)}}
                placeholder={userLang} />
                </div>
                
            {/* <div className='title-icon'>
              <select style={{color:'var(--icons-color)', backgroundColor:'var(--body-background-color)'}} defaultValue={i18n.language} onChange={onChangeLang}>
                {languages.map(({code, label}) => (
                <option key={code} value={code}>
                  {label}
                </option>
              ))}
              </select>
            </div> */}



            {/*<Select options={themes} value={userTheme}
                onChange={(e) => setUserTheme(e.value)}
                placeholder={userTheme} /> */}
            
            

            {/* 
            <label>Font Size</label>
            <input type="range" min="18" max="30"
                value={fontSize} step="2"
                onChange={(e) => { setFontSize(e.target.value) }} /> */}

                <div className='title-icon'>
                <FontAwesomeIcon icon={faGear} style={{color:'var(--icons-color)'}} size="2x"  />
                
                {/* {Cookies.get('Code')} */}
                </div>

                <div className='title-icon'>
                {(localStorage.getItem('Token')!=null) ?
                  <a href="/Logout"><FontAwesomeIcon icon={faSignOut} style={{color:'var(--icons-color)'}} size="2x"/></a>
              :
                  <a href="/Login"><FontAwesomeIcon icon={faSignIn} style={{color:'var(--icons-color)'}} size="2x"/></a>    
                }

                </div>


                <div className='title-icon'>

                <select style={{color:'var(--icons-color)', backgroundColor:'var(--body-background-color)'}} defaultValue={i18n.language} onChange={onChangeLang}>
                {nLanguages.map(({code, label}) => (
                    <option key={code} value={code}>
                      {label}
                      </option>
                ))}
                </select>
                </div>
                

                {/* for multiple languages use following link
                https://dev.to/franklin030601/building-a-multi-language-app-with-react-js-2och */}


                {t("home")}
        </div>
    )
}
 
export default Navbar