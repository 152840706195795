const languages = [
    {
    id: 1,
    name: "Java (OpenJDK 13.0.1)",
    value: "java",
    label: "java",
  },
  {
    id: 2,
    name: "Python (3.8.1)",
    value: "python3",
    label: "python3",
  },
  {
    id: 3,
    name: "JavaScript (Node.js 12.14.0)",
    value: "javascript",
    label: "javascript",
  },
  {
    id: 4,
    name: "Octave (5.1.0)",
    value: "matlab",
    label: "matlab",
  },
  {
    id: 5,
    name: "C (Clang 7.0.1)",
    value: "c",
    label: "c",
  },
  {
    id: 6,
    name: "C++ (Clang 7.0.1)",
    value: "cpp",
    label: "cpp",
  },
  {
    id: 7,
    name: "SQL (SQLite 3.27.2)",
    value: "sql",
    label: "sql",
  },
  {
    id: 8,
    name: "HTML",
    value: "html",
    label: "html",
  },
  {
    id: 9,
    name: "Markdown",
    value: "markdown",
    label: "markdown",
  },
  {
    id: 10,
    name: "CSS",
    value: "css",
    label: "css",
  },
  {
    id: 11,
    name: "JSON",
    value: "json",
    label: "json",
  },
  {
    id: 12,
    name: "XML",
    value: "xml",
    label: "xml",
  },
  {
    id: 13,
    value: "Plain Text",
    name: "Plain Text",
    label: "text",
  },
];

export default languages;