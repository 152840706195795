import {Navigate} from "react-router-dom";
import Axios from 'axios';


const Logout = ({Cookies}) => {
  const token = localStorage.getItem('Token');
  const url = process.env.REACT_APP_API_URL;

  if(token)
  {
    const headers = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    
    Axios.get(`${url}/api/user/logout`, headers)
    .then((res) => {
      // Cookies.remove('Code')
      localStorage.removeItem('Token');
    }).catch((err) => {
      // setError(err);
      console.log(err);
    })
  }
  else{
    return window.location.href = '/login';
  }
  return <Navigate to="/login" />;
}

export default Logout;



// https://github.com/js-cookie/js-cookie