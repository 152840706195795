import React from "react";
import { ReactComponent as Sun } from "./Sun.svg";
import { ReactComponent as Moon } from "./Moon.svg";
import "./DarkMode.css";

const DarkMode = ({userTheme, setUserTheme}) => {
    const setDarkMode = (e) => {
        document.querySelector('body').setAttribute('mode', 'vs-dark');
        setUserTheme('vs-dark');
    };
    const setLightMode = (e) => {
        document.querySelector('body').setAttribute('mode', 'light');
        setUserTheme('light');
    };
    const toggleTheme=(e)=>{
        if(e.target.checked)
            setDarkMode();
        else
            setLightMode();
        
    };
    return (
        <div className='dark_mode'>
            <input
                className='dark_mode_input'
                type='checkbox'
                id='mode-toggle'
                // onChange={(e) => setUserTheme(e.value)}
                onChange={toggleTheme}
                checked={userTheme==='vs-dark'}
            />
            <label className='dark_mode_label' htmlFor='mode-toggle'>
                <Sun />
                <Moon />
            </label>
        </div>
    );
};

export default DarkMode;
