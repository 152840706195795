import i18n from "i18next";
import {initReactI18next} from "react-i18next";

i18n
//   .use(i18nBackend)
  .use(initReactI18next)
  .init({
    fallbackLng: "en",
    // lng: getCurrentLang(),
    lng: "en",
    interpolation: {
      escapeValue: false,
    },
    resources: {
        ar: {
            translation: {
                title: "تطبيق متعدد اللغات",
                label: "حدد لغة أخرى!",
                about: "حول",
                home: "الصفحة الرئيسية",
                run: "تشغيل",
                input: "المدخلات",
                output: "النتائج",
                sendVerify: "يرجى التحقق من بريدك الإلكتروني للحصول على رابط التحقق",
                Err401: "البريد الإلكتروني الخاص بك غير مسجل",
                Err402: "تحتاج إلى تأكيد البريد الإلكتروني الخاص بك",
                Err404: "البريد الإلكتروني أو كلمة المرور الخاصة بك غير صحيحة",
                lb01: "البريد الإلكتروني",
                lb02: "كلمة السر",
                authorization01: "ليس لديك صلاحية استخدام هذه الخدمة",
            },
        },
      en: {
        translation: {
          title: "Multi-language app",
          label: "Select another language!",
          about: "About",
          home: "Home",
          run: "Run",
          input: "Inputs",
          output: "Outputs",
          sendVerify: "Please check your email for the verification link",
          Err401: "Your email is not registered",
          Err402: "Your email is not verified, please go to your email and verify it.",
          Err404: "Your email or password is incorrect",
          lb01: "Email",
          lb02: "Password",
          authorization01: "You do not have access to use this service",
        },
      },
      es: {
        translation: {
          title: "Aplicación en varios idiomas",
          label: "Selecciona otro lenguaje!",
          about: "Sobre mí",
          home: "Inicio",
          run: "Correr",
          input: "Entradas",
          output: "Resultados",
          sendVerify: "Por favor, revise su correo electrónico para el enlace de verificación",
          Err401: "Su correo electrónico no está registrado",
          Err402: "Necesita verificar su correo electrónico",
          Err404: "Su correo electrónico o contraseña es incorrecta",
          lb01: "Correo electrónico",
          lb02: "Contraseña",
          authorization01: "",
        },
      },
      it: {
        translation: {
          title: "Applicazione multilingue",
          label: "Selezionare un'altra lingua ",
          about: "Su di me",
          home: "Casa",
          run: "Eseguire",
          input: "Ingressi",
          output: "Uscite",
          sendVerify: "Si prega di controllare la propria email per il link di verifica",
          Err401: "La tua email non è registrata",
          Err402: "Devi verificare la tua email",
          Err404: "La tua email o password non è corretta",
          lb01: "E-mail",
          lb02: "Parola d'ordine",
          authorization01: "",
        },
      },
    },
  });

export default i18n;