import React, { useState } from 'react';
// import {Navigate} from "react-router-dom";

import Form from "react-bootstrap/Form";
import Stack from "react-bootstrap/Stack";
import Button from "react-bootstrap/Button";
import Axios from 'axios';
import { useTranslation } from "react-i18next";


// we are using the useState hook to store what the user enters in the form. 

const Login = ({Cookies}) => {
  const [error, setError] = useState(null);

    // function allcookies(){
    //     let ret = '';
    //     for (let i = 0; i < Cookies.length; i++) {
    //         ret += i + ' - ' + Cookies[i] + "\n";
    //     }
    //     return ret;
    // }


  // const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const {t} = useTranslation();


  function validateForm() {
    return email.length > 0 && password.length > 0;
  }

  // function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    // Setting headers with Axios global configuration
  //   const setAuthToken =(token) => {
  //     // if (token) {
  //       Axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  //     // }
  //     // else
  //         // delete Axios.defaults.headers.common["Authorization"];
  //  }

  const handleSubmit = (e) =>{
    e.preventDefault();
    // console.log(email);

    const loginPayload = {
      email: email.toLocaleLowerCase(),
      password: password
    }
    const url = process.env.REACT_APP_API_URL;
      // Post request to compile endpoint
        Axios.post(`${url}/api/auth/login`, loginPayload)
        .then((res) => {
            // setUserOutput(res.data);

            // Store the JWT in localStorage
            localStorage.setItem("Token", res.data.token);

            // return <Navigate to="/signup" />;

            window.location.href = '/';



            // Set JWT as a cookie
            // Cookies.set("Code", res.data.token, {
            //   // httpOnly: true,
            //   // zmaxAge: 1000 * 60 * 1 * 30,  // 30 minutes
            //   expires: 2,
            // });





             //set token to axios common header
            // setAuthToken(res.data.token);
            // Axios.defaults.headers.common["Authorization"] = `Bearer ${res.data.token}`;
            // console.log(res.data)

            // res.redirect('/');

        }).catch((err) => {

            // setLoading(false);
            // setError(err.response.data.error);
            setError(err);
            // alert(error);
            // dd
            console.log(err);
        })

  }


  

    return (
    <div className="Login">
      <Form onSubmit={handleSubmit}>
        <img src='logo.jpg' alt='The logo' width={320} height={200}/><br /><br /><br /><br />
        
      
      {/* {error != null &&
        <div  style={{ color: 'red', backgroundColor: 'lightgray', padding: '10px', marginTop: '10px' }}>{error.response.data.message}</div>
      } */}


      {error!= null &&  error.response.status===401 &&
        <div  style={{ color: 'red', backgroundColor: 'lightgray', padding: '10px', marginTop: '10px' }}>{t("Err401")}</div>
      }

      {error!= null &&  error.response.status===402 &&
        <div  style={{ color: 'red', backgroundColor: 'lightgray', padding: '10px', marginTop: '10px' }}>{t("Err402")}</div>
      }

      

      {error!= null &&  error.response.status===404 &&
        <div  style={{ color: 'red', backgroundColor: 'lightgray', padding: '10px', marginTop: '10px' }}>{t("Err404")}</div>
      }
      
      
        <Stack gap={3}>

          <Form.Group controlId="email">
            <Form.Label>{t("lb01")}: </Form.Label>
            <Form.Control
              autoFocus
              size="lg"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}

            />
          </Form.Group>
          <Form.Group controlId="password">
            <Form.Label>{t("lb02")}: </Form.Label>
            <Form.Control
              size="lg"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </Form.Group>
          <Button size="lg" type="submit" disabled={!validateForm()}>
            تسجيل الدخول
          </Button>
        </Stack>
          <br />
          لايوجد لديك حساب لدى SysWalk؟
          <br />
          <a href='signup'>تسجيل</a>
      </Form>

      
    </div>
    )
}

export default Login;



// https://github.com/js-cookie/js-cookie