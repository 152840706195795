import { faMartiniGlass } from '@fortawesome/free-solid-svg-icons';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { dark } from 'react-syntax-highlighter/dist/esm/styles/prism';

import React, { useState } from 'react'; // for settings
import Popup from './Popup';            //for settings

function HomePage() {

//for settings
  const [isOpen, setIsOpen] = useState(false);
 
  const togglePopup = () => {
    setIsOpen(!isOpen);
  }
  const Handler=()=> {
    alert("Hello! I am an alert box!");
  }
//for settings


  const codeString = `public class test2{
    public static void main(String[] args){
          for(int i=0;i<115968;i++)
              System.out.println(i+1+" dd");
    }
  }`;
    return (
<div>
  <SyntaxHighlighter language="javascript" style={docco}>
    {codeString}
  </SyntaxHighlighter>
  
  <img src="logo.jpg" alt="logo"/>







{/* //for settings */}
  <input type="button" value="Click to Open Popup" onClick={togglePopup} />
    {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p> */}
    {isOpen && <Popup
      content={<>
        <b>Settings</b>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
        <input type='button' value="Test" onClick={Handler} />
      </>}
      handleClose={togglePopup}
    />}
{/* //for settings */}





</div>
    );
 }
 
 export default HomePage;



