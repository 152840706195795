import React, { useState } from 'react';
import Form from "react-bootstrap/Form";
import Stack from "react-bootstrap/Stack";
import Button from "react-bootstrap/Button";
import Axios from 'axios';


// we are using the useState hook to store what the user enters in the form. 

const Signup = ({Cookies}) => {
  const [error, setError] = useState(null);

    // function allcookies(){
    //     let ret = '';
    //     for (let i = 0; i < Cookies.length; i++) {
    //         ret += i + ' - ' + Cookies[i] + "\n";
    //     }
    //     return ret;
    // }


  const [username, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");

  function validateForm() {
    // const pusername = /^[a-z](?:[a-z]+\d*|\d{2,})$/i;
    const pusername = /^([a-zA-Z]+\d*[_-]*){3,}$/;
    const pemail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    const ppassword =(password === password2)
    

    return pemail.test(email) && password.length > 0 && pusername.test(username) && ppassword;
  }

  // function handleSubmit(e: React.FormEvent<HTMLFormElement>) {

  const handleSubmit = (e) =>{

    e.preventDefault();
    console.log(email);

    const loginPayload = {
      username: username.toLocaleLowerCase(),
      email: email.toLocaleLowerCase(),
      password: password
    }
    const url = process.env.REACT_APP_API_URL;


      // Post request to compile endpoint
        Axios.post(`${url}/api/auth/signup`, loginPayload)
        .then((res) => {
            // setUserOutput(res.data);
            window.location.href = '/login';
            // console.log(res.data);
            // res.redirect('/');
        }).catch((err) => {
            // setLoading(false);
            setError(err.response.data.message);
            console.log(err);
        })

  }


    return (

    <div className="Login">
      <Form onSubmit={handleSubmit}>
      <img src='logo.jpg' alt='The logo' width={320} height={200}/><br /><br /><br /><br />

      {error != null &&
        <div  style={{ color: 'red', backgroundColor: 'lightgray', padding: '10px', marginTop: '10px' }}>Error: {error}</div>
      }
        <Stack gap={3}>
        <Form.Group controlId="username">
            <Form.Label>اسم المستخدم: </Form.Label>
            <Form.Control
              autoFocus
              size="lg"
              type="text"
              value={username}
              onChange={(e) => setUserName(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="email">
            <Form.Label>البريد الإلكتروني: </Form.Label>
            <Form.Control
              autoFocus
              size="lg"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="password">
            <Form.Label>كلمة السر: </Form.Label>
            <Form.Control
              size="lg"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="password2">
            <Form.Label>إعادة كلمة السر: </Form.Label>
            <Form.Control
              size="lg"
              type="password"
              value={password2}
              onChange={(e) => setPassword2(e.target.value)}
            />
          </Form.Group>
          <Button size="lg" type="submit" disabled={!validateForm()}>
            تسجيل
          </Button>
        </Stack>
      </Form>
    </div>
    )
}

export default Signup;



// https://github.com/js-cookie/js-cookie