// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root{
  --body-background-color: #f5f5f5;
  --navbar-background-color:whitesmoke;
  --icons-color: black;
  --Labels1:  black;
  --Labels2:  black;

  --color: black;

  /* --body-background-color: #d9d9d9; */
  /* --background-color: #ebebeb; */

  
  /* --background-color: #474747; */
  /* --bakground-color: #242424; */
}

[mode="vs-dark"] {
  --body-background-color: #242424;
  --navbar-background-color:#474747;
  --icons-color: white;
  --Labels1:  #afec3f;
  --Labels2:  white;

  --color: whitesmoke;
}

*{
  transition: all 0.3s ease-in-out;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}




@media all and (min-width: 480px) {
  .Login {
    padding: 60px 0;
    /* direction: rtl; */
    :disabled {background-color:gainsboro;}

  }

  .Login form {
    margin: 0 auto;
    max-width: 320px;
  }
}







/*


https://sst.dev/chapters/create-a-login-page.html
*/`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,gCAAgC;EAChC,oCAAoC;EACpC,oBAAoB;EACpB,iBAAiB;EACjB,iBAAiB;;EAEjB,cAAc;;EAEd,sCAAsC;EACtC,iCAAiC;;;EAGjC,iCAAiC;EACjC,gCAAgC;AAClC;;AAEA;EACE,gCAAgC;EAChC,iCAAiC;EACjC,oBAAoB;EACpB,mBAAmB;EACnB,iBAAiB;;EAEjB,mBAAmB;AACrB;;AAEA;EACE,gCAAgC;AAClC;AACA;EACE,SAAS;EACT;;cAEY;EACZ,mCAAmC;EACnC,kCAAkC;AACpC;;AAEA;EACE;aACW;AACb;;;;;AAKA;EACE;IACE,eAAe;IACf,oBAAoB;IACpB,WAAW,0BAA0B,CAAC;;EAExC;;EAEA;IACE,cAAc;IACd,gBAAgB;EAClB;AACF;;;;;;;;AAQA;;;;CAIC","sourcesContent":[":root{\n  --body-background-color: #f5f5f5;\n  --navbar-background-color:whitesmoke;\n  --icons-color: black;\n  --Labels1:  black;\n  --Labels2:  black;\n\n  --color: black;\n\n  /* --body-background-color: #d9d9d9; */\n  /* --background-color: #ebebeb; */\n\n  \n  /* --background-color: #474747; */\n  /* --bakground-color: #242424; */\n}\n\n[mode=\"vs-dark\"] {\n  --body-background-color: #242424;\n  --navbar-background-color:#474747;\n  --icons-color: white;\n  --Labels1:  #afec3f;\n  --Labels2:  white;\n\n  --color: whitesmoke;\n}\n\n*{\n  transition: all 0.3s ease-in-out;\n}\nbody {\n  margin: 0;\n  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',\n    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\n    sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\n    monospace;\n}\n\n\n\n\n@media all and (min-width: 480px) {\n  .Login {\n    padding: 60px 0;\n    /* direction: rtl; */\n    :disabled {background-color:gainsboro;}\n\n  }\n\n  .Login form {\n    margin: 0 auto;\n    max-width: 320px;\n  }\n}\n\n\n\n\n\n\n\n/*\n\n\nhttps://sst.dev/chapters/create-a-login-page.html\n*/"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
