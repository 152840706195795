import { useState, useEffect } from 'react';

import Axios from 'axios';

const Browse=({userOutput})=> {
  const [user_name,setuser_name] = useState('')
  const [result,setResult] = useState('')


  
  useEffect (()=>{
    
    const token = localStorage.getItem('Token');
    const url = process.env.REACT_APP_API_URL;
    const headers = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    Axios.get(`${url}/api/user/get_username`, headers) 
    .then(res => setuser_name(res.data.result)) 
    .catch(console.error) 







    
    
      const headers2 = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      };
      const Payload = {
        filename: `./data/projects/${user_name}/_hiddenoutput.txt`,
      }
      // Post request to get the selected file
        Axios.post(`${url}/api/files/getResult/`, Payload, headers2)
        .then((res) => {
            setResult( res.data.result);
    })
    .catch(console.error) 

  






  });
  
    return (
      <div>
        {/* username: 
        {user_name}
        {result}
        {getResult(files.fullpath)} */}

        <iframe title="frame1" name="theframe" srcDoc={result} style={{position: 'absolute', width: '100%', height: '100%', border: 'none'}}></iframe> 
      </div>
    );
 }
 
 export default Browse;