import { useState } from "react";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFile, faFolder} from "@fortawesome/free-solid-svg-icons";
// import { FaList, FaRegHeart, FaRegFileAlt, FaPlus, FaFolderPlus } from "react-icons/fa";

const Directory = ({ files, getFile, save, setFileCode }) => {

    const [expand, setExpand] = useState(false);

    // if (files.type === 'folder') {
    //     return (
    //         <div className="folder">
    //             {/* <h5 className="folder-title" onClick={() => setExpand(!expand)}><FontAwesomeIcon icon={FaFolderPlus} />{' '+files.name}</h5><br /> */}
    //             <MenuItem onClick={(e) => setExpand(!expand)}> <FontAwesomeIcon icon={faFolder} /> {'  '+files.name} </MenuItem>
    //             {
    //                 expand && files.items.map((item) => <Directory files={item} />)
    //             }
    //         </div>
    //     )
    // }


    // return (
    //     <div>
    //       <FontAwesomeIcon icon={faFolder} />
    //       <span
    //         style={{
    //           cursor: "pointer",
    //           paddingLeft: "5px"
    //         }}
    //         onClick={() => setExpand(!expand)}
    //       >
    //         {files.name}
    //         <br />
    //       </span>
    //       <div
    //         style={{
    //           display: expand ? "block" : "none",
    //           paddingLeft: "15px",
    //           cursor: "pointer"
    //         }}
    //       >
    //         {" "}
    //         {files.items.map((exp, idx) => {
    //           return (
    //             <>
    //               <Directory key={idx} Directory={exp} />
    //             </>
    //           );
    //         })}
    //       </div>
    //     </div>
    //   );

    // return (
    //     <>
    //         {/* <h3 className="file-name"><FontAwesomeIcon icon={faFileLines} />{'  '+files.name}</h3><br /> */}
    //         {/* <MenuItem onClick={(e) => setExpand(!expand)}> <FontAwesomeIcon icon={faFile} /> {'  '+files.name} </MenuItem> */}
    //         <div style={{
    //             cursor: "default",
    //             paddingLeft: "5px"
    //         }}><FontAwesomeIcon icon={faFile} /> 
    //         {files.name}
    //         <br />
    //         </div>
    //     </>
    // )













    if (files.items) {
      return (
        <div >
          
          <span  style={{cursor: "pointer", paddingLeft: "5px"}}
            onClick={() => setExpand(!expand)}>
              <FontAwesomeIcon icon={faFolder} />
              <span  style={{cursor: "pointer", paddingLeft: "5px"}} >
                {files.name}
                </span>
          </span>
          <div style={{display: expand ? "block" : "none", paddingLeft: "15px", cursor: "pointer"}}>
            {" "}
            
            {files.items.map((exp, idx) => {
              
              return (
                <>
                  <Directory key={idx} tabIndex ={-1} files={exp} 
                  getFile={getFile}
                  save={save}
                  // save={() => save()}
                  setFileCode={setFileCode}
                 
                  />
                </>
              );
            })}
          </div>
        </div>
      );
    } 
    else {
      return (
        <div tabIndex ={-1} className ='menu-item' onClick={(e) => {save();getFile(files.fullpath);setFileCode(files.fullpath)}}>
          <span 
          // style={{cursor: "pointer", paddingLeft: "5px"}}
          >
            <FontAwesomeIcon icon={faFile} />
            {' '+files.name}
          </span>
          
          
          <br />
        </div>
      );
    }

    


}

export default Directory;