import { useState } from 'react';

import { BrowserRouter, Routes, Route } from "react-router-dom";
import {faExternalLink} from "@fortawesome/free-solid-svg-icons";

import './App.css';
import Editor from "@monaco-editor/react";
import Navbar from './Components/Navbar';
import Sidebar from './Components/Sidebar';
import Signup from './Components/Signup';
import Login from './Components/Login';
import Logout from './Components/Lgout';
// import RightContext from './Components/RightContext';
import { useTranslation } from "react-i18next";
// import i18n from "./Components/i18n/i18n";
import {Navigate} from "react-router-dom";




import Home from './Components/Home';
import Verify from './Components/Verify';
import Browse from './Components/Browse';
import parse from "html-react-parser";


// import UserProfile from './Components/UserProfile';
import Cookies from 'js-cookie';


import Axios from 'axios';
import spinner from './spinner.svg';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faFile } from "@fortawesome/free-solid-svg-icons";
// import { faPlay } from "@fortawesome/free-solid-svg-icons";

// require('dotenv').config();

// import env from "react-dotenv";



// import spinner from 'https://raw.githubusercontent.com/n3r4zzurr0/svg-spinners/abfa05c49acf005b8b1e0ef8eb25a67a7057eb20/preview/wifi-black-36.svg';

 
function App() {

  function handleKeyDown(event){
    if ((event.ctrlKey || event.metaKey) && event.key === "s") {
      event.preventDefault(); // Prevent the default browser behavior (saving the webpage)
      save();
      alert("ctrl + s pressed");
    }
  }

    const { i18n, t } = useTranslation();

    const [userName, setUserName] = useState('');
    
    // An array of content of home directory for user. 
    const [files, setFiles] = useState([]);

 
    // State variable to set SourceCode
    const [userCode, setUserCode] = useState(``);

    // Flag that indicates the content has changed
    const [contentChange, setcontentChange] = useState(false);


    // The name of the currentFile
    const [fileCode, setFileCode] = useState(``);


 
    // State variable to set editors default language
    const [userLang, setUserLang] = useState("java");

    // State variable to set editors default language
    const [userLang_id, setUserLang_id] = useState(1);
 
    // State variable to set editors default theme
    // const [userTheme, setUserTheme] = useState("vs-dark");
    const [userTheme, setUserTheme] = useState("light");
 
    // State variable to set editors default font size
    const [fontSize, setFontSize] = useState(16);
 
    const [sizes, setSizes] = useState([100, '30%', 'auto']);

    // State variable to set users input
    const [userInput, setUserInput] = useState("");
 
    // State variable to set users output
    const [userOutput, setUserOutput] = useState("");

    const [elapsedTime, setElapsedTime] = useState(0);

    // Loading state variable to show spinner
    // while fetching data
    const [loading, setLoading] = useState(false);


 
    const options = {
        minimap: {
            enabled: false,
          },
        fontSize: fontSize,
        scrollbar: {
          alwaysConsumeMouseWheel: false
         },
         scrollBeyondLastLine: false
        // cursorStyle: "block",
        // wordWeap: "on",
    }

    const [collapsed, setCollapsed] = useState(false);
    // const [toggled, setToggled] = useState(false);


    // function getResult(filename) {

    //   // const token = process.env.REACT_APP_Token;
    //   const token = localStorage.getItem('Token');
    //   const url = process.env.REACT_APP_API_URL;
    
    
    //   const headers = {
    //     headers: {
    //       Authorization: `Bearer ${token}`
    //     }
    //   };
    //   const Payload = {
    //     filename: filename,
    //   }
    //   // Post request to get the selected file
    //     Axios.post(`${url}/api/files/getResult/`, Payload, headers)
    //     .then((res) => {
    //         return res.data.result;
    // })
    
    //     // let ext = filename.substring(filename.indexOf('.'));
    // }

    function walk(){
      const token = localStorage.getItem('Token');
      // console.log('token is '+ process.env.REACT_APP_Token);
      const headers = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      };
      const url = process.env.REACT_APP_API_URL;
      
      const Payload = {
        // username: localStorage.getItem('Code')
      }

    // curl -i -X POST ${process.env.REACT_APP_API_URL}/api/files/getFiles -H 'Authorization: Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJsb2dnZWRpbiI6dHJ1ZSwidXNlcm5hbWUiOiJzYWxyZWhhaWxpIiwidXNlcl9pZCI6MSwiZW1haWwiOiJzYWxyZWhhaWxpQGdtYWlsLmNvbSIsImlhdCI6MTcwNjYwMjc1NiwiZXhwIjoxNzA2Nzc1NTU2fQ.IN-55AbuntTK7FM9QFaSsO6jM_xKD09O-8XHOCt5ib0' -d '{}'
      
    Axios.post(`${url}/api/files/FolderStructure`, Payload, headers)
      .then((res)=>{
        setFiles(res.data.result);
        // console.log(res.data.result)
      }).catch((err)=>{
        console.log('errrssssss='+err.message);
        console.log(err.response.data)
      })

    }



    function save() {

      const ext = fileCode.split('.').pop();
      if(contentChange || ext==='html' || ext===''){
        setcontentChange(false);
      
        // const token = process.env.REACT_APP_Token;
        const token = localStorage.getItem('Token');

        const headers = {
            headers: {
              Authorization: `Bearer ${token}`
            }
          };
          const url = process.env.REACT_APP_API_URL;

          const Payload = {
            sourceCode: userCode,
            currentFile: fileCode,
          }
          // console.log(Payload)

          if(fileCode){
      // Post request to compile endpoint
        Axios.post(`${url}/api/files/save`, Payload, headers)
        .then((res) => {
            setUserOutput(res.data);

         })
            // .then(() => {
        //     setLoading(false);
        // })
            .catch((e) => {
                if (e.response) {
                    setUserOutput('Server Error:', e.response.status);
                }
                else if (e.request) {
                    setUserOutput('Network Error:', e.request);
                }
                else
                {
                    setUserOutput('Error:', e.message);
                }

                setUserOutput(e);
              });
          }


     
      }
    }


    // Function to call the compile endpoint
    // function compile() {
    //     setLoading(true);
    //     if (userCode === ``) {
    //         return
    //     }
 
    //     // Post request to compile endpoint
    //     Axios.post(`${process.env.REACT_APP_API_URL}/compile`, {
    //         code: userCode,
    //         language: userLang_id,
    //         input: userInput
    //     }).then((res) => {
    //         setUserOutput(res.data.stdout);

    //     }).then(() => {
    //         setLoading(false);
    //     })
    // }

    // Function to call the compile endpoint
    function localcompile() {
        setLoading(true);
        if (userCode === ``) {
            return
        }

        

        // const token = process.env.REACT_APP_Token;
        const token = localStorage.getItem('Token');

        const headers = {
            headers: {
              Authorization: `Bearer ${token}`
            }
          };

          const url = process.env.REACT_APP_API_URL;

        // Post request to compile endpoint
        Axios.post(`${url}/api/compile`,
        {
            input:userInput,
            currentFile: fileCode,
            userLang_id: userLang_id,
            // username: localStorage.getItem('Code')
        // },)
        }, headers)
        .then((res) => {
            setUserOutput(res.data.result);
            setElapsedTime(res.data.elapsedTime);
            // setUserOutput(process.env.API_URL)

        })
        .then(() => {
            setLoading(false);
        }).catch((err) => {
            setUserOutput(err.response.data.error);
            setElapsedTime(err.response.data.elapsedTime);
        }).then((err) => {
            setLoading(false);
        });
    }
 
    // Function to clear the output screen
    function clearOutput() {
        setUserOutput("");
    }

    function loaddata(){
      if(!userName) {
        get_username();
      }
      if(files.length==0)
        {
          walk();
        }
    }
    function getBarIcon() {
        
        return  <FontAwesomeIcon icon={faFile} style={{color:'var(--icons-color)'}} onClick={(e) => {loaddata();setCollapsed(!collapsed) }}  color="white" size="2x" cursor='pointer'/>;
        // return  <FontAwesomeIcon icon={faBars} onClick={(e) => { setToggled(!toggled) }}  color="white" size="2x"/>;
    }
  
  // function getUser() {
  //       return Cookies.get('name');
  //   }
  //   function setUser(username) {
  //       // Cookies.set('name', username); 

  //       var inFifteenMinutes = new Date(new Date().getTime() + 3 * 60 * 1000);
  //       Cookies.set('name', 'Salrehaili', {
  //           expires: inFifteenMinutes
  //       });


  //   }

  const get_username=()=>{
      
    const token = localStorage.getItem('Token');
    const url = process.env.REACT_APP_API_URL;

    if(token)
    {
      const headers = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      };
      Axios.get(`${url}/api/user/get_username`, headers)
      .then((res) => {
        setUserName(res.data.result);
      }).catch((err) =>{
        // return <Navigate to="/login" />;
        return window.location.href = '/login';
      });
    }
    else{
    //   return <Navigate to="/login" />;
    return window.location.href = '/login';
    }
   
  }
  function Header() {
    return (
        <header>
           <Navbar
                getBarIcon={getBarIcon}
                userLang={userLang} setUserLang={setUserLang}
                userLang_id={userLang_id} setUserLang_id={setUserLang_id}
                userTheme={userTheme} setUserTheme={setUserTheme}
                fontSize={fontSize} setFontSize={setFontSize}
                save={save}
                localcompile={localcompile}
                Cookies ={Cookies}
                userName={userName} 
            />
        </header>
    )}
//   function Main() {
//     return (

//     )
//   }

  function Footer() {
    return (
        <footer>
            <p style={{fontSize:'7pt', bottom:'0'}}>{userLang}</p>
        </footer>
    )}

  function InputBox() {
                            
    // if(userTheme === 'vs-dark')
    //     return <textarea style={{ backgroundColor:'black', color:'white'}} id="code-inp" onChange={(e) => setUserInput(e.target.value)}></textarea>
    //  else
    //     return <textarea style={{ backgroundColor:'white', color:'black'}} id="code-inp" onChange={(e) => setUserInput(e.target.value)}></textarea>    
    
    return <textarea id="code-inp" onChange={(e) => setUserInput(e.target.value)}></textarea>
    }

    function createPage(content){
      let file = fileCode.substring(fileCode.lastIndexOf('/')+1);
      let spath = fileCode.substring(0, fileCode.lastIndexOf('/')+1);

      // let page = document.open("./../backend/"+spath+".temp/"+file,"_blank")
      // alert("./../backend/"+spath+".temp/"+file);
      // page.document.write(content)


      const myWindow = document.open('', '_blank');
      // myWindow.open();
      myWindow.write(content);
      myWindow.close();
      // myWindow.document.open('text/html', 'replace')
      // myWindow.document.write(content);
      // myWindow.document.close();
  }

  function OutputBox() {
    let ext = fileCode.substring(fileCode.indexOf('.'))
    if(ext==='.html')
    {
        // const str =userOutput;
        // const pattern = /<link.+?href=\"(.+\.css)\".*?>/g;
        
        // // const match = pattern.exec(str);
        // // const match = str.match(pattern);
        // // if(match)
        // // {
        // //     console.log(match);
        // // }
        // var i=0;
        // for(let match of str.matchAll(pattern)){
        //     let [full, value]=match;
        //     i++;
        //     console.log(value+ ' '+i);
        // }
        // onClick={ window.open("", "", "width=600,height=400,left=200,top=200")}
        
        // console.log(/css/.exec(userOutput));

        // let w = window.open("", "Widdow01", "width=200,height=100");
        // w.document.write(`<iframe name="theframe" srcDoc=${userOutput}></iframe>`);
        // return createPage(userCode);
        return <><iframe title="frame1" name="theframe" srcDoc={userOutput} width="100%"></iframe> 
        {/* {userOutput.length>0 &&  */}
          {/* <a target='_blank' href="#"  onClick={()=>createPage(userOutput)}>new tab</a>  */}
          
        {/* } */}
        </>
        
        // return <iframe name="theframe" srcDoc={userOutput}></iframe>
    }
    else if (ext==='.sql'){
      return <iframe title="frame2" name="theframe" srcDoc={userOutput} width="100%" ></iframe>
    }
    // if(userTheme === 'vs-dark')
    //     return <pre style={{ backgroundColor:'black', color:'white'}}>{parse (userOutput)}</pre>
    //  else
    //     return <pre style={{ backgroundColor:'white', color:'black'}} >{parse(userOutput)}</pre>    
    return parse(userOutput)
    }


    // function handleEditorValidation(markers) {
    //   // model markers
    //   markers.forEach((marker) => console.log('onValidate:', marker.message));
    // }

    
    return (
        <BrowserRouter>
      <Routes>
      <Route path="/" element={
        <div onKeyDown={handleKeyDown} className="App">

       



            

            <Header />
            
            {/* <Main /> */}

            <main>

                {
                // If not collapsed then show the sidebar
                collapsed  && 
                <Sidebar
                    collapsed={collapsed}
                    setCollapsed={setCollapsed}
                    setUserCode={setUserCode}
                    setFileCode={setFileCode}
                    setUserLang_id={setUserLang_id}
                    setUserLang={setUserLang}
                    save={save}
                    walk={walk}
                    files={files}
                    setFiles={setFiles}
                    setUserOutput={setUserOutput}
                  
                />

                
                }

                <div className='container'>
                    <div className="top-container">



                        <Editor
                            options={options}
                            // height="calc(100vh - 50px)"
                            // height="75vh"
                            // width="100vw"
                            theme={userTheme}
                            language={userLang}
                            defaultLanguage="java"
                            defaultValue="# Enter your code here"
                            onChange={(value) => {setcontentChange(true);setUserCode(value);}}
                            value={userCode}
                            // onValidate={handleEditorValidation}
                        />

                        {/* <button className="run-btn" onClick={() => {save();localcompile()}}>
                        <FontAwesomeIcon icon={faPlay} />Run
                        </button> */}
                    </div>
                    <div className="buttom-container">
                        {/* <h4>{t("input")}:</h4>
                        <div className="input-box">
                            {InputBox()}
                        </div> */}
                        
                        <h4 style={{display: "inline"}}>{t("output")}: &emsp; </h4> <a target='_blank' href="/browse" > <FontAwesomeIcon icon={faExternalLink} style={{color:'var(--icons-color)'}} size="1.5x"/></a> 
                        {loading ? (
                            <div className="spinner-box">
                                <img src={spinner} alt="Loading..." />
                            </div>
                        ) : (
                            <div className="output-box">
                                {OutputBox()} <br /> 
                                <span style={{fontSize: '10px'}}>
                                  {" elapsed time: "+elapsedTime}
                                </span>
                                
                                <button onClick={() => { clearOutput() }}
                                    className="clear-btn">
                                    Clear
                                </button>
                            </div>



                        )}
                    </div>
                    <Footer />
                </div>
            </main>
           


        </div>

           
        }/>

        {/* <Route path="home" element={<Home Cookies={Cookies}/>} /> */}
        <Route path="home" element={<Home/>}/>
        <Route path="verify" element={<Verify/>}/>
        <Route path="signup" element={<Signup/>} />
        <Route path="login" element={<Login/>} />
        <Route path="logout" element={<Logout/>} />
        <Route path="browse" element={<Browse userOutput={userOutput}/>}/>




        
        
        
        </Routes>
        </BrowserRouter>
    );
}
 
export default App;