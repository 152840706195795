import React, { useState, useEffect } from 'react';
// import Select from 'react-select';
// import '../Styles/Navbar.css';
import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import Directory from './Directory';
// import files2 from '../files.json';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faFileLines, faFile, faFolder, faFolderPlus, faFileCirclePlus} from "@fortawesome/free-solid-svg-icons";
import { FaList, FaFolderPlus } from "react-icons/fa";
import Axios from 'axios';
import Form from "react-bootstrap/Form";

// import dotenv from 'dotenv-webpack';
// import dotenv from 'dotenv';
// require('dotenv').config();

 


const MySidebar = ({collapsed, setCollapsed,setUserCode, setFileCode, setUserLang_id, setUserLang, save, walk, files, setFiles, setUserOutput}) => {

  // document.getElementById("messageBox").focus();

    // const [files, setFiles] = useState([]);
    const [currentFile, setcurrentFile] = useState("");

   

    // const languages = [
    //     { value: "c", label: "C" },
    //     { value: "cpp", label: "C++" },
    //     { value: "python", label: "Python" },
    //     { value: "java", label: "Java" },
    // ];


function download(filename) {
  const url = process.env.REACT_APP_API_URL;

      // Post request to compile endpoint
        Axios.get(`${url}/api/files/download/${filename}`, {
            // code: userCode,
            // params:{
            //    file: filename 
            // }
            
           
         }).then((res) => {
            setUserCode(res.data);

        // }).then(() => {
        //     setLoading(false);
        // })
    })

            
}


    function getFile(filename) {

      // const token = process.env.REACT_APP_Token;
      const token = localStorage.getItem('Token');
      const url = process.env.REACT_APP_API_URL;


      const headers = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      };
      const Payload = {
        filename: filename,
        // username: localStorage.getItem('Code')
      }
      // Post request to get the selected file
        Axios.post(`${url}/api/files/getFile/`, Payload, headers)
        .then((res) => {
            setUserCode(res.data.result);
            // setUserOutput("");

        // }).then(() => {
        //     setLoading(false);
        // })
    })

        // let file = filename.substring(0, filename.indexOf('.'));
        let ext = filename.substring(filename.indexOf('.'));


        switch (ext) {
        case '.java':
            setUserLang_id(1);
            setUserLang('java');
            break;
        case '.py':
            setUserLang_id(2);
            setUserLang('python');
            break;
        case '.cpp':
            setUserLang_id(6);
            setUserLang('cpp');
            break;
        case '.c':
            setUserLang_id(5);
            setUserLang('c');
            break;
        case '.js':
            setUserLang_id(3);
            setUserLang('javascript');
            break;
        case '.html':
            setUserLang_id(8);
            setUserLang('html');
            break;
        case '.css':
            setUserLang_id(10);
            setUserLang('css');
            break;
        case '.json':
            setUserLang_id(11);
            setUserLang('json');
            break;
        case '.sql':
          setUserLang_id(7);
          setUserLang('sql');
          break;
        case '.xml':
            setUserLang_id(12);
            setUserLang('xml');
            break;
        case '.m':
            setUserLang_id(4);
            setUserLang('matlab');
            break;
        case '.md':
            setUserLang_id(9);
            setUserLang('markdown');
            break;
        default:
            setUserLang_id(1);
            setUserLang('java');
    }

        
}
    




// useEffect(() => {
//     async function getUsers() {
        
//       const response = await fetch('${process.env.REACT_APP_API_URL}/api/files/getFiles', {
//         method: 'POST',
//         headers: {
//           accept: 'application/json',
//         },
//         body: JSON.stringify({username: localStorage.getItem('Code')})
//       });

//       const data = await response.json();

//       setFiles(data.result);
//     }

//     getUsers();
//   }, []);






// useEffect(() => {
//   async function getUsers() {

//     const Payload = {
//       username: localStorage.getItem('Code')
//     }
      
//     const response = await Axios.post('${process.env.REACT_APP_API_URL}/api/files/getFiles', Payload);
    
    

//     const data = await response.data;

//     setFiles(data.result);
//   }

//   getUsers();
// }, []);




// useEffect(() => {
//   async function getUsers() {

//     const url = process.env.REACT_APP_API_URL;

//     // const token = process.env.REACT_APP_Token;
//     const token = localStorage.getItem('Token');
//     // console.log('token is '+ process.env.REACT_APP_Token);
//     const headers = {
//       headers: {
//       Authorization: `Bearer ${token}`
//     }
//   };

//     const Payload = {
//       // username: localStorage.getItem('Code')
//     }

//     // curl -i -X POST ${process.env.REACT_APP_API_URL}/api/files/getFiles -H 'Authorization: Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJsb2dnZWRpbiI6dHJ1ZSwidXNlcm5hbWUiOiJzYWxyZWhhaWxpIiwidXNlcl9pZCI6MSwiZW1haWwiOiJzYWxyZWhhaWxpQGdtYWlsLmNvbSIsImlhdCI6MTcwNjYwMjc1NiwiZXhwIjoxNzA2Nzc1NTU2fQ.IN-55AbuntTK7FM9QFaSsO6jM_xKD09O-8XHOCt5ib0' -d '{}'
      
//     Axios.post(`${url}/api/files/getFiles`, Payload, headers)
//       .then((res)=>{
//         setFiles(res.data.result);
//       }).catch((err)=>{
//         console.log('errrrrrrrrrr='+err.message);
//       })
    
    

//   }

//   getUsers();
// }, []);


  
function mkFile1(){
  document.getElementById("popUpForm").style.display = "block";

}

function mkFolder1(){
  document.getElementById("popUpForm_folder").style.display = "block";

}

const handleKeyDown = (event) => {
  if (event.key === 'Enter') {
    event.preventDefault();
    mkFile();
  }
}

const handleKeyDown_folder = (event) => {
  if (event.key === 'Enter') {
    event.preventDefault();
    mkFolder();
  }
}

const mkFile = () =>{
  const url = process.env.REACT_APP_API_URL;

  // const token = process.env.REACT_APP_Token;
  const token = localStorage.getItem('Token');
  // console.log('token is '+ process.env.REACT_APP_Token);
  const headers = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  };

  const Payload = {
    currentFile: currentFile,
  }
  
  Axios.post(`${url}/api/files/mkFile`, Payload, headers)
  .then((res)=>{
    // setFiles(res.data.result);
    // getUsers();
    // setCollapsed(false);
    walk();
    document.getElementById("popUpForm").style.display = "none";


  
  }).catch((err)=>{
    console.log('errrrrrrrrrr='+err.message);
  })
}

const mkFolder = () =>{
  const url = process.env.REACT_APP_API_URL;

  // const token = process.env.REACT_APP_Token;
  const token = localStorage.getItem('Token');
  // console.log('token is '+ process.env.REACT_APP_Token);
  const headers = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  };

  const Payload = {
    currentFile: currentFile,
  }
  
  Axios.post(`${url}/api/files/mkFile`, Payload, headers)
  .then((res)=>{
    // setFiles(res.data.result);
    // getUsers();
    // setCollapsed(false);
    walk();
    document.getElementById("popUpForm").style.display = "none";


  
  }).catch((err)=>{
    console.log('errrrrrrrrrr='+err.message);
  })
}

const commentEnterSubmit = (e) => {
  if (e.key === "Enter" || e.which == 13 || e.keyCode == 13) {
    setcurrentFile(e.target.value)
  }
  // mkFile(); 
    
  }
  

    return (

        <div className="sidebar">

            
            <Sidebar 
            collapsedWidth="100%" 
            // backgroundColor="#d9d9d9"
            // rootStyles={{width:"200px" }}
            collapsed={collapsed}
         
            >
              
              
                <Menu iconShape="square" 

                
               menuItemStyles={{
      button: ({ level, active, disabled }) => {
        // only apply styles on first level elements of the tree

        if (level === 1)
          return {
            color: disabled ? '#f5d9ff' : '#d359ff',
            backgroundColor: active ? '#eecef9' : undefined,
            collapsed: true,
            toggled:true,
          };
      },
    }}
                >
                  
                  {/* <MenuItem onClick={(e) => {mkFile1()}} icon={<FaPlus />}></MenuItem> */}
                  {/* <MenuItem icon={<FaFolderPlus />}></MenuItem> */}

                  {/* <span onClick={(e) => {mkFile1()}}> <FontAwesomeIcon icon="fa-solid fa-file-plus"/>hh+</span> */}
                  <span onClick={(e) => {mkFile1()}} ><FontAwesomeIcon icon={faFileCirclePlus} style={{color:'var(--icons-color)', paddingLeft: '10px'}}/></span>
                  
                  {/* <span> <FontAwesomeIcon icon="fa-folder-plus"/>f+</span> */}
                  <span onClick={(e) => {mkFolder1()}}> <FontAwesomeIcon icon={faFolderPlus} style={{color:'var(--icons-color)', paddingLeft: '10px'}}/></span>

                  <Directory
                  // key={0}
                  files={files}
                  getFile={getFile}
                  save={save}
                  // save={() => save()}
                  setFileCode={setFileCode}
                  
                  />

                  {//<SubMenu label="Project01"  >
                  }

                    {/* <MenuItem icon={<FaList />}>Category</MenuItem> */}
                    <div className="form-popup" id="popUpForm" style={{ display:'none', position: 'relative'}}>
                      <Form>
                        {/* <input type="text" value={currentFile} onChange={(e) => setcurrentFile(e.target.value)} name="currentFile" id="currentFile" placeholder="" /> */}
                        <Form.Control
                          autoFocus
                          size="lg"
                          type="text"
                          value={currentFile}
                          onChange={(e) => setcurrentFile(e.target.value)}
                          onKeyDown={handleKeyDown}
                          // onChange={(e) => commentEnterSubmit(e)}
                          />
                       
                        {/* <button type="button" onClick={mkFile} >+</button> */}
                      </Form>                      
                    </div>



                    <div className="form-popup_folder" id="popUpForm_folder" style={{ display:'none', position: 'relative'}}>
                      <Form>
                        {/* <input type="text" value={currentFile} onChange={(e) => setcurrentFile(e.target.value)} name="currentFile" id="currentFile" placeholder="" /> */}
                        <Form.Control
                          autoFocus
                          size="lg"
                          type="text"
                          value={currentFile}
                          onChange={(e) => setcurrentFile(e.target.value)}
                          onKeyDown={handleKeyDown_folder}
                          // onChange={(e) => commentEnterSubmit(e)}
                          />
                       
                        {/* <button type="button" onClick={mkFile} >+</button> */}
                      </Form>                      
                    </div>

                    {/* <MenuItem onClick={(e) => {getFile("testj.java"); setFileCode('testj.java') }} > <FontAwesomeIcon icon={faFileLines} /> testj.java <a href="sdfs">ddd</a></MenuItem>
                    <MenuItem onClick={(e) => {getFile("test2.java");setFileCode('test2.java') }}> <FontAwesomeIcon icon={faFile} /> test2.java </MenuItem>
                    <MenuItem onClick={(e) => {getFile("ptest.py");setFileCode('ptest.py') }}> <FontAwesomeIcon icon={faFileLines} /> ptest.py </MenuItem>
                    <MenuItem onClick={(e) => {getFile("test.cpp");setFileCode('test.cpp') }}> <FontAwesomeIcon icon={faFileLines} /> test.cpp </MenuItem>
                    <MenuItem onClick={(e) => {getFile("testc.c");setFileCode('testc.c') }}> <FontAwesomeIcon icon={faFileLines} /> testc.c </MenuItem>
                    <MenuItem onClick={(e) => {getFile("main.js");setFileCode('main.js') }}> <FontAwesomeIcon icon={faFileLines} /> main.js </MenuItem>
                    <MenuItem onClick={(e) => {getFile("index.js");setFileCode('index.js') }}> <FontAwesomeIcon icon={faFileLines} /> index.js </MenuItem> */}


                 
                  {/* { 
                  // Array.isArray(users)?
                  files?.map((file, index) => (
                  <Menu key={index}>
                    { !file.type && currentFile.length==0 &&
                        // <MenuItem onClick={(e) => {save();getFile(file.name);setFileCode(file.name) }}> <FontAwesomeIcon icon={faFileLines} />{' '+file.name}</MenuItem>

                        <div tabindex ={index}className ='menu-item' onClick={(e) => {getFile(file.name);setFileCode(file.name) }}> <FontAwesomeIcon icon={faFileLines} />{' '+file.name}<br /></div>
                    }
                    
                    { !file.type && currentFile.length>0 &&
                        // <MenuItem onClick={(e) => {save();getFile(file.name);setFileCode(file.name) }}> <FontAwesomeIcon icon={faFileLines} />{' '+file.name}</MenuItem>

                        <div tabindex ={index}className ='menu-item' onClick={(e) => {save();getFile(file.name);setFileCode(file.name) }}> <FontAwesomeIcon icon={faFileLines} />{' '+file.name}<br /></div>
                    }



                    { file.type &&
                        // <SubMenu label={file.name}>
                            <MenuItem onClick={(e) => {save();getFile(file.name);setFileCode(file.name) }}> <FontAwesomeIcon icon={faFolder} />{' '+file.name} </MenuItem>
                            

                            // <div>
                            //   <FontAwesomeIcon icon={faFolder} />
                            //   <span style={{cursor: "pointer", paddingLeft: "5px"}} onClick={() => setExpand(!expand)}>
                            //     {files.name}
                            //     <br />
                            //     </span>
                                
                            //     <div style={{display: expand ? "block" : "none", paddingLeft: "15px", cursor: "pointer"}}>
                            //       {" "}{files.items.map((exp, idx) => {
                            //         return (
                            //         <>
                            //         <Directory key={idx} files={exp} />
                            //         </>
                            //         );
                            //         })}
                            //       </div>
                            //   </div>
                        // </SubMenu>
                    }
                </Menu>
                       


                       
                        ))
                        } */}
                       
                {/* <SubMenu label="Charts">
                    <MenuItem> Project02 </MenuItem>
                </SubMenu> */}
    

                  {/* <MenuItem> Project03 </MenuItem> */}
                </Menu>
              </Sidebar>
              
        </div>


//<div className="sidebar">
//<Directory files={files2} />
//</div>

    )
}
 
export default MySidebar